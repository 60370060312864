import React, { forwardRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import settings from "../../../settings";

const BMWM3 = `${settings.IMAGES_BASE_URL}/v2/images/cars/bmw-m3.jpg`;
const BMWM4 = `${settings.IMAGES_BASE_URL}/v2/images/cars/bmw-m4.jpg`;
const BMWM5 = `${settings.IMAGES_BASE_URL}/v2/images/cars/bmw-m5.jpg`;



const Power = forwardRef((props, ref) => {
	return (
		<section className="power" ref={ref}>
			<div className="container">
				<div className="striped-background">
					<div className="blue-stripe" />
					<div className="darkblue-stripe" />
					<div className="red-stripe" />
				</div>
				<div className="power__header">
					<h2>POWER. POISE. PRECISION.</h2>
					<p>
						Each M car offers the same core of performance while bringing something unique to the table.
						Please note that vehicle availability may vary.
					</p>
				</div>
			</div>
			<div className="power__content">
				<div className="row">
					<div className="power__item">
						<LazyLoadImage
							effect="blur"
							src={BMWM3}
							alt="The BMW M3 Competition"
						/>
						<h3>The BMW M3 Competition</h3>
						<p>
							The icon returns with 503 horses and that hallmark M handling
							ability.
						</p>
					</div>
					<div className="power__item">
						<LazyLoadImage effect="blur" src={BMWM4} alt="The BMW M4" />
						<h3>The BMW M4 Competition</h3>
						<p>
							A perfect balance of precision and power, the BMW M4 is an ace on
							the track with 503 horsepower on tap.
						</p>
					</div>
					<div className="power__item">
						<LazyLoadImage
							effect="blur"
							src={BMWM5}
							alt="The BMW M5 Competition"
						/>
						<h3>The BMW M5 Competition</h3>
						<p>
							The first BMW M sedan with M xDrive, you’ll get to experience how
							this amazing technology helps to control 617 horsepower.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
});
export default Power;
